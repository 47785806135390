@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@200;400;500&display=swap');
body {
  margin: 0;
  font-family: 'Poppins-ExtraLight, Poppins-Regular , Poppins-Medium , Poppins Medium, Poppins',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  overflow-x: hidden !important;
  box-sizing: border-box;
}

#root {
  background: white;
}
.infinite-scroll-component {
  overflow: unset !important;
}

.crio-loading {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
}
input.ant-input,
.crio-input input,
.signin-input input {
  background-color: #fafbfc !important;
  font-size: 14px !important;
  font-family: 'Poppins-Light', 'Poppins Light', 'Poppins', sans-serif !important;
  font-weight: 200;
}
.ant-form-item-explain-error {
  color: #c04040 !important;
  font-family: 'Poppins-Light', 'Poppins Light', 'Poppins', sans-serif !important;
  font-weight: 300 !important;
  font-size: 14px !important;
  line-height: 19px !important;
}
.ant-input-status-error,
.ant-select-status-error,
:where(
    .css-dev-only-do-not-override-1s3dcof
  ).ant-input-affix-wrapper-status-error:not(
    :where(
        .css-dev-only-do-not-override-1s3dcof
      ).ant-input-affix-wrapper-disabled
  ):not(
    :where(
        .css-dev-only-do-not-override-1s3dcof
      ).ant-input-affix-wrapper-borderless
  ):where(.css-dev-only-do-not-override-1s3dcof).ant-input-affix-wrapper,
:where(.css-dev-only-do-not-override-1s3dcof).ant-input-status-error:not(
    :where(.css-dev-only-do-not-override-1s3dcof).ant-input-disabled
  ):not(
    :where(.css-dev-only-do-not-override-1s3dcof).ant-input-borderless
  ):where(.css-dev-only-do-not-override-1s3dcof).ant-input {
  border-bottom: 3px solid #c04040 !important;
  margin-bottom: 10px !important;
}
.ant-form-item-label label {
  font-family: 'Poppins-Regular', 'Poppins', sans-serif !important;
  color: #666666 !important;
}
.ant-tooltip {
  --antd-arrow-background-color: #fff !important;
}
.ant-tooltip-inner {
  font-family: 'Poppins-Regular', 'Poppins', sans-serif !important;
  background-color: #fff !important;
  color: rgb(102, 102, 102) !important;
  padding: 20px !important;
  font-size: 14px !important;
}
.ant-popover-inner {
  padding: 20px !important;
  box-shadow: 0px 0px 5px 0px rgba(0, 0, 0, 0.25) !important;
  border-radius: 10px !important;
}
.ant-divider-dashed {
  border-color: rgba(5, 5, 5, 0.2) !important;
}
.vertical-divider-class {
  height: 70% !important;
  margin: 0 !important;
  border-inline-start: unset !important;
  border: 1px dashed #e3761c !important;
}

/*          HEADER  START           */
.crio-signup-button {
  width: 90%;
  margin-top: 10px;
}
.crio-login-button-big,
.crio-signup-button-big,
.crio-signup-button {
  font-family: 'Poppins-Medium', 'Poppins Medium', 'Poppins', sans-serif !important;
  font-weight: 500 !important;
  color: #ffffff !important;
  border-radius: 3px;
  font-size: 14px !important;
  height: 40px !important;
  padding: 10px 30px 10px 30px !important;
  border-radius: 3px !important;
}
.crio-signup-button-big {
  background-color: #e3761c;
}
.crio-signup-button-big:hover {
  background-color: #ff9743 !important;
}
.header {
  height: 80px;
  color: white;
  background-color: rgb(13, 43, 58);
  font-size: 18px;
  display: flex;
  padding: 0 8%;
  justify-content: space-between;
  align-items: center;
}

.header ul {
  display: flex;
  align-items: center;
  margin: 0% !important ;
  padding-left: 0 !important;
}

.header ul li {
  list-style: none;
  font-family: 'Poppins-Regular', 'Poppins', sans-serif !important;
  margin-left: 18px;
}

.logo {
  display: flex;
  justify-content: center;
  align-items: center;
}
.logo img,
.logo2 img {
  height: 60px;
  cursor: pointer;
}
.logo2 {
  display: none;
  justify-content: center;
  align-items: center;
}

/*         HEADER  END       */

/*         FOOTER  END       */

.footer {
  /* height: 170px; */
  width: 100%;
  background-color: rgb(13, 43, 58);
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  color: #2d92b2;
  text-align: center;
  padding-top: 50px;
}
.footer-branding {
  margin-bottom: 16px;
  font-size: 14px;
  font-family: 'Poppins-Regular', 'Poppins', sans-serif;
  display: flex;
  align-items: end;
}
.footer-branding img {
  height: 34px;
  margin-right: 10px;
}
.footer-rights {
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-family: 'Poppins-ExtraLight', 'Poppins ExtraLight', 'Poppins', sans-serif;
  width: 90%;
  font-weight: 100 !important;
  font-size: 14px;
  max-width: 900px;
  min-height: 80px;
}
.footer-rights a {
  text-decoration: none !important;
}
.footer-rights p {
  margin: 0 !important;
}
.footer-rights a:nth-child(1),
.footer-rights a:nth-child(2) {
  color: rgba(255, 255, 255, 0.5) !important;
  font-family: 'Poppins-Regular', 'Poppins', sans-serif !important;
  font-weight: 400 !important;
}

/*         FOOTER  END       */
/*         HOME  START       */
.hero {
  background-color: rgb(13, 43, 58);
  padding: 0 10%;
  height: 448px;
  display: flex;
  align-items: center;
  box-shadow: 5px 12px 10px 0px rgba(192, 192, 192, 0.35);
  background-image: url('/src/assets/images/publicfacing-rocket.svg');
  background-repeat: no-repeat;
  background-position-y: 102%;
  background-position-x: 72%;
}

.hero-text {
  font-family: 'Poppins-Regular', 'Poppins', sans-serif !important;
  color: #ffffff;
  font-size: 1.5em;
  padding-left: 2em;
}

.body-content {
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  margin-top: -4em;
}

.body-content {
  overflow: hidden;
}

.body-text {
  margin: 80px 0 100px 0;
  width: 50%;
  text-align: center;
}

.body-text span {
  font-weight: 500;
}
.promotion-text h2 {
  font-family: 'Poppins-Medium', 'Poppins Medium', 'Poppins', sans-serif;
  font-weight: 500;
  color: #002639;
  font-size: 28px !important;
  margin-bottom: 29px;
}
.promotion-text p span {
  font-weight: 500;
}
.promotion-text p,
.promotion-text p span,
.body-text {
  font-size: 18px;
  font-family: 'Poppins-Regular', 'Poppins', sans-serif !important;
  color: #333333;
  line-height: 36px;
}
.promotion-text {
  max-width: 450px;
}
.promotional-cards-wrapper {
  width: 100%;
}
.promotional-cards-header {
  width: 100%;
  height: 274px;
  background-color: #0d2b3a;
  background-image: url('/src/assets/images/homepage-background-pattern.png');
  background-repeat: no-repeat;
  background-position-y: 45%;
  background-position-x: 50%;
  background-blend-mode: soft-light;
  display: flex;
  justify-content: center;
  z-index: 1;
}
.promotional-cards-header h1 {
  font-family: 'Poppins-Medium', 'Poppins Medium', 'Poppins', sans-serif;
  font-weight: 500;
  color: #fff;
  font-size: 28px !important;
  max-width: 650px;
  text-align: center;
  margin-top: 70px;
  line-height: normal;
}
.promotional-cards-container {
  width: 100%;
  background-color: #fafafa !important;
  display: flex;
  justify-content: center;
  align-items: center;
}

.promotional-cards-inner {
  width: 85%;
  margin-top: -80px;
  display: flex;
  justify-content: center;
}
.promotional-card {
  height: 338px;
  background-color: #fff;
  border-radius: 10px 10px 0 0 !important;
  border-bottom: 5px solid rgba(13, 43, 58, 0.47);
  padding: 20px;
  display: flex;
  flex-direction: column;
  align-items: center;
}
.promotional-card h3 {
  font-family: 'Poppins-Medium', 'Poppins Medium', 'Poppins', sans-serif;
  font-weight: 500;
  color: #0d2b3a;
  text-align: center;
  font-size: 18px !important;
  margin-top: 45px;
}
.promotional-card p {
  font-family: 'Poppins-Regular', 'Poppins', sans-serif;
  color: #333333;
  text-align: center;
  line-height: 36px;
  font-size: 18px;
  margin-top: 28px;
  max-width: 312px;
}
.home-signup {
  background-color: #2f4356;
  width: 100%;
  padding: 0 12%;
}
.home-signup-right img {
  height: 100%;
  padding-top: 40px;
  width: 100%;
  max-height: 500px;
  max-width: 500px;
}
.home-signup-left {
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: 40px 0;
}
.home-signup-left h2 {
  font-family: 'Poppins-Medium', 'Poppins Medium', 'Poppins', sans-serif;
  font-weight: 500;
  color: #ffffff;
  text-align: left;
  line-height: normal;
  max-width: 550px;
  font-size: 28px;
  margin-bottom: 40px !important;
}
.home-signup-button {
  height: 54px;
  padding: 10px 0px 10px 0px;
  border-radius: 3px;
  background-color: #e3761c;
  font-family: 'Poppins-Medium', 'Poppins Medium', 'Poppins', sans-serif !important;
  font-weight: 500;
  color: #ffffff;
  font-size: 14px;
  max-width: 250px;
  background-color: #e3761c;
}
.home-signup-button:hover {
  background-color: #ff9743 !important ;
}
.home-signup-left p {
  font-family: 'Poppins-Regular', 'Poppins', sans-serif;
  color: #ffffff;
  text-align: left;
  font-size: 18px;
  line-height: normal;
  max-width: 450px;
  margin-bottom: 45px !important;
}

.option-text {
  font-family: 'Poppins-Light', 'Poppins Light', 'Poppins', sans-serif !important;
  font-weight: 200 !important;
  color: #666666 !important;
  font-size: 12px !important;
  padding: 0% !important;
}

.promotion-row {
  max-width: 90%;
  margin-bottom: 100px;
}

.promotion-col {
  display: flex !important;
  justify-content: center;
  align-items: center;
}
.promotion-img {
  height: 70%;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.promotion-img img {
  width: 80%;
}
.promotion-row-card {
  background-color: #fafafa;
  width: 100%;
  padding: 120px 40px;
  display: flex;
  justify-content: center;
  align-items: center;
}
.promotion-row-card .promotion-col {
  justify-content: center !important;
}
.promotion-card-left {
  font-family: 'Poppins-Medium', 'Poppins Medium', 'Poppins', sans-serif !important;
  font-weight: 500;
  color: #002639;
  text-align: left;
  font-size: 38px;
  max-width: 460px;
}
.promotion-card-left h2 {
  margin-bottom: 0% !important;
  font-family: 'Poppins-Medium', 'Poppins Medium', 'Poppins', sans-serif;
  font-weight: 500;
  color: #002639;
  text-align: center;
  font-size: 38px;
  line-height: normal;
}
.promotion-card-right {
  width: 100%;
}
.promotion-card-right-row {
  display: flex;
  justify-content: space-between;
}

.promotion-card-right-row div {
  width: 45%;
  font-size: 28px;
  color: #666666;
  margin: 20px 0;
  text-align: center;
}
.promotion-card-right-row div p {
  font-size: 18px;
  font-family: 'Poppins-Regular', 'Poppins', sans-serif !important;
  color: #333333 !important;
  margin-top: 15px;
  line-height: normal;
}

/*         HOME  END       */

/*       SEARCH BAR STARTS    */
.search-card {
  width: 50%;
  height: 100px;
  border-radius: 10px !important;
  padding: 0 !important;
  box-shadow: 5px 5px 20px 0px rgba(186, 186, 186, 0.18) !important;
  border: 3px solid #e3761c !important;
}
.search-card .ant-card-body {
  padding: 0 !important;
}
.small-selected-label {
  display: none;
}
.search-warning-tooltip .ant-tooltip-content {
  width: inherit !important;
}
.search-warning-tooltip .ant-tooltip-arrow::after {
  width: 15.313708px !important;
  height: 15.313708px !important;
  border: 2px solid #ffb7b7 !important;
}
.ant-tooltip-placement-bottomLeft .ant-tooltip-arrow {
  top: 2px !important;
  left: 35px !important;
}
.search-bar {
  height: 100%;
  display: flex !important;
  justify-content: space-evenly;
  align-items: center;
}
.search-bar-card-wrapper:hover {
  background-color: #f0f0f0;
}
.check-loader-spin-wrapper {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 50px;
}
.select-bar {
  width: 35% !important;
  padding: 20px;
  border-radius: 5px !important;
  display: flex;
  flex-direction: column;
  overflow: hidden;
  font-family: 'Poppins-Regular', 'Poppins', sans-serif;
  color: #0d2b3a;
  font-size: 14px;
}
.check-options {
  font-family: 'Poppins-Light', 'Poppins Light', 'Poppins', sans-serif !important;
  font-weight: 200;
  color: #666666;
  font-size: 12px;
  padding: 12px;
  width: 100%;
  display: flex !important;
  align-items: center !important;
}
.check-options-facility {
  font-family: 'Poppins-Regular', 'Poppins', sans-serif !important;
  color: #666666 !important;
  padding: 9px 0;
  width: 100%;
  display: flex !important;
  align-items: center !important;
}
.check-options:hover,
.check-options-facility:hover,
.check-options-selected {
  background-color: #f0f0f0;
  font-family: 'Poppins-Regular', 'Poppins', sans-serif !important;
  font-weight: 400 !important;
}
.select-button {
  cursor: pointer;
  font-size: 15px;
  font-family: 'Poppins-ExtraLight', 'Poppins ExtraLight', 'Poppins', sans-serif;
  font-weight: 300;
  color: #999999;
  width: 100%;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
.big-selected-label {
  font-size: 15px !important;
  font-family: 'Poppins-Medium', 'Poppins Medium', 'Poppins', sans-serif !important;
  font-weight: 500 !important;
  color: #0d2b3a !important;
}
.search-bar-card-wrapper:hover .select-button,
.search-bar-card-wrapper:hover .big-selected-label,
.search-bar-card-wrapper:hover .small-selected-label {
  color: rgb(0, 156, 235) !important;
}
.option-box {
  max-width: 312px;
  padding: 34px 12px !important;
}
.option-box-facility {
  max-width: 650px;
  padding: 34px 12px !important;
}
.crio-dropdown-title {
  font-family: 'Poppins-Regular', 'Poppins', sans-serif !important;
  color: #0d2b3a !important;
  font-size: 14px !important;
  font-weight: 400 !important;
}
.therapeutic-dropdown {
  width: 100%;
  max-height: 267px;
  overflow-y: auto;
}
.facility-dropdown {
  padding-bottom: 2px;
  width: 100%;
  overflow-y: auto;
}

.search-div {
  width: 10% !important;
  margin: 10px;
  max-width: 60px;
}

.search-button {
  background-color: #4e6d8b;
  color: #fff;
  font-size: 1.7em;
  padding: 30%;
  cursor: pointer;
  border-radius: 3px;
}
.search-button:hover {
  background-color: #425b75;
}
.ant-card-body {
  height: 100%;
}

/*       SEARCH BAR Ends    */

/*         LOGIN-SIGNUP  START      */

.spin-loader-div-container {
  height: 70vh;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.agreement-body-text {
  max-height: 217px;
  overflow: auto;
  padding: 20px 40px 20px 20px;
  border: 1px solid #e6eaf2;
  background-color: #fafbfc;
  font-size: 12px;
  font-family: 'Poppins-ExtraLight', 'Poppins ExtraLight', 'Poppins', sans-serif;
  font-weight: 300;
  color: #666666;
}

.terms-checkbox {
  font-family: 'Poppins-ExtraLight', 'Poppins ExtraLight', 'Poppins', sans-serif;
  font-weight: 300;
  color: #666666;
  font-size: 14px;
}

.checkbox-div {
  margin-top: -10px !important;
  margin-bottom: 0 !important;
}

.error-otp-span {
  color: #c04040 !important;
  font-family: 'Poppins-Light', 'Poppins Light', 'Poppins', sans-serif !important;
  font-weight: 300 !important;
  font-size: 14px !important;
  line-height: 19px !important;
  height: 30px !important;
  padding: 10px 0;
}
.error-otp {
  border-bottom: 3px solid #c04040 !important;
}
.login-page-body {
  padding: 20px;
  width: 650px;
  max-width: 100%;
  margin: auto;
}
.login-page-card {
  margin: 20px !important;
  border-radius: 0% !important;
  border-top: 2px solid rgba(3, 180, 255, 0) !important;
  box-shadow: 0px 0px 5px 0px rgba(0, 0, 0, 0.25) !important;
}
.otp-input-span {
  width: 15px !important;
}
.otp-input {
  margin-top: 39px;
  height: 67px !important;
  width: 50px !important;
  font-family: 'Poppins-Light', 'Poppins Light', 'Poppins', sans-serif !important;
  border-radius: 0% !important;
  font-weight: 200;
  font-size: 20px !important;
  text-align: center !important;
  border: 1px solid #e6eaf2;
  background-color: #fafbfc !important;
}
.signin {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}
.sign-in-logo-text {
  font-size: 32px;
  font-family: 'Poppins-Medium', 'Poppins Medium', 'Poppins', sans-serif;
  font-weight: 500;
  color: #666666;
  text-align: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.sign-in-logo-text img {
  height: 66px;
  margin: 20px;
}
.signin-form {
  width: 82%;
  margin: 35px 0;
}

.signin-input {
  height: 40px;
  font-size: 14px !important;
  font-family: 'Poppins-Light', 'Poppins Light', 'Poppins', sans-serif !important;
  border-radius: 0% !important;
  font-weight: 200;
  text-align: left;
  border: 1px solid #e6eaf2 !important;
  background-color: #fafbfc !important;
}
.signin-button {
  margin-top: 8px;
  height: 40px;
  width: 100%;
  border-radius: 3px;
  border: 2px solid #079ae0 !important;
  background-color: #19b5ff !important;
  font-family: 'Poppins-Medium', 'Poppins Medium', 'Poppins', sans-serif;
  font-weight: 500;
  color: #ffffff !important;
}
.signin-button-disabled {
  margin-top: 8px;
  height: 40px;
  width: 100%;
  border-radius: 3px;
  border: 2px solid #c1c1c1 !important;
  background-color: #c1c1c1 !important;
  font-family: 'Poppins-Medium', 'Poppins Medium', 'Poppins', sans-serif;
  font-weight: 500;
  color: #ffffff !important;
}
.signin-button:hover {
  background-color: #079ae0 !important;
}
.signin-button-decline {
  margin-top: 8px;
  height: 40px;
  width: 100%;
  border-radius: 3px;
  border: 2px solid #e3761c !important;
  background-color: #fff !important;
  font-family: 'Poppins-Medium', 'Poppins Medium', 'Poppins', sans-serif;
  font-weight: 500;
  color: #e3761c !important;
}
.signin-button-decline:hover {
  background-color: #e3761c !important;
  color: #fff !important;
}
.signin-button-accept {
  margin-top: 8px;
  height: 40px;
  width: 100%;
  border-radius: 3px;
  border: 2px solid #315b86 !important;
  background-color: #315b86 !important;
  font-family: 'Poppins-Medium', 'Poppins Medium', 'Poppins', sans-serif;
  font-weight: 500;
  color: #fff !important;
}
.signin-button-accept:hover {
  background-color: #345270 !important;
  color: #fff !important;
}
.create-button {
  font-family: 'Poppins', sans-serif;
  margin: 30px 0;
  color: #19b5ff;
  text-align: center;
  text-decoration: none;
}
.login-profile-pic {
  height: 48px;
  width: 48px;
  overflow: hidden;
  border-radius: 100%;
  border: 1px solid #cccccc;
  margin-right: 10px;
}
.login-profile-pic img {
  max-height: 100%;
  max-width: 100%;
}

.user-email {
  margin-top: 20px;
  font-family: 'Poppins-Regular', 'Poppins', sans-serif;
  color: #666666;
  font-size: 14px;
  display: flex;
  justify-content: center;
  align-items: center;
}
.forget-password-link {
  font-family: 'Poppins-Regular', 'Poppins', sans-serif;
  color: #666666;
  text-align: right;
  font-size: 12px;
  margin-top: -15px;
}
.change-email {
  cursor: pointer;
  width: 80%;
  font-family: 'Poppins-ExtraLight', 'Poppins ExtraLight', 'Poppins', sans-serif;
  font-weight: 300;
  font-size: 14px;
  color: #999999;
  text-align: left;
  margin-bottom: 29px;
}
.change-email:hover {
  color: #079ae0;
}
.login-card-text {
  margin-top: 10px;
  font-family: 'Poppins-Regular', 'Poppins', sans-serif !important;
  font-weight: 300;
  color: #666666;
  text-align: center;
  font-size: 14px;
}

.PhoneInputCountry {
  display: flex;
  flex-direction: row-reverse;
  justify-content: flex-end;
  align-items: center;
  margin: 0 8px;
}
.PhoneInputCountrySelect {
  width: 20px;
  border: 0;
}
.PhoneInputCountryIcon {
  width: 40px;
}
.PhoneInputInput {
  all: unset;
  background: none;
  border: none;
  padding: 8px;
  width: -webkit-fill-available;
}
.PhoneInput {
  display: flex;
  justify-content: center;
  align-items: center;
}
.login-purpose-dropdown {
  padding: 10px 10px 2px 10px;
  border: 1px solid #e6eaf2;
  background-color: #fafbfc !important;
  font-family: 'Poppins-ExtraLight', 'Poppins ExtraLight', 'Poppins', sans-serif !important;
  font-weight: 200 !important;
  color: #cccccc;
}

/*         LOGIN-SIGNUP  END        */

/*         SEARCH-PAGE START        */
.demo-warning-modal {
  display: flex;
  flex-direction: column;
  align-items: center;
  background:
    url('/src/assets/images/normal_u1089.svg') left 30% no-repeat,
    url('/src/assets/images/normal_u1092.svg') right 35% no-repeat;
}
.demo-warning-modal img {
  max-width: 400px;
  width: 100%;
}
.demo-warning-modal h3 {
  max-width: 388px;
  font-family: 'Poppins-Medium', 'Poppins Medium', 'Poppins', sans-serif !important;
  font-size: 22px;
  font-weight: 500;
  color: #0d2b3a;
  text-align: center;
}
.demo-warning-modal p {
  max-width: 388px;
  font-family: 'Poppins-Regular', 'Poppins', sans-serif !important;
  color: #666666;
  text-align: center;
  font-size: 14px;
}
.schedule-demo-button {
  background-color: #e3761c !important;
  font-family: 'Poppins-Medium', 'Poppins Medium', 'Poppins', sans-serif;
  font-weight: 500;
  color: #ffffff;
  width: 250px;
  height: 40px;
  border-radius: 3px !important;
  margin: 15px 0;
}
.schedule-demo-button:hover {
  background-color: rgb(253, 153, 51) !important;
  color: #ffffff !important;
  outline: none !important;
  border: none !important;
}
.schedule-demo-login-button {
  font-family: 'Poppins-Regular', 'Poppins', sans-serif !important;
  color: #009ceb !important;
  font-size: 14px !important;
  margin-bottom: 10px;
}
.schedule-demo-login-button:hover {
  background: none !important;
}
.schedule-demo-login-button:hover span {
  text-decoration: underline !important;
}

.search-section {
  width: 100%;
  padding: 35px 4%;
  display: flex;
  justify-content: center;
  align-items: center;
  box-shadow: 5px 5px 10px 0px rgba(192, 192, 192, 0.35);
  margin-bottom: 20px;
}
.body-container {
  margin: 0 8% 90px 8%;
}

.sites-header {
  font-family: 'Poppins-Medium', 'Poppins Medium', 'Poppins', sans-serif;
  color: #0d2b3a;
  font-size: 28px;
  margin: 38px 0;
  display: flex;
  font-weight: 500;
  align-items: center;
}
.sites-header p {
  padding: 3px 10px 3px 10px;
  border-radius: 3px;
  border: 1px solid #cccccc;
  background-color: #e4e4e4;
  font-family: 'Poppins-Regular', 'Poppins', sans-serif;
  color: #666666;
  font-size: 12px;
  margin: 0 0 0 20px !important;
}

.search-signup-card {
  width: 100%;
  background-color: rgba(227, 118, 28, 0.11);
  margin-bottom: 20px;
  padding: 20px;
}
.search-signup-card h2 {
  font-family: 'Poppins-Medium', 'Poppins Medium', 'Poppins', sans-serif;
  font-weight: 500;
  color: #e3761c;
  font-size: 16px;
  margin: 15px 0;
}
.search-signup-card p {
  font-family: 'Poppins-Regular', 'Poppins', sans-serif;
  color: #4e6d8b;
  font-size: 14px;
  margin-bottom: 20px;
}
.search-signup-card a {
  font-size: 12px;
  font-family: 'Poppins-Light', 'Poppins Light', 'Poppins', sans-serif !important;
  font-weight: 400 !important;
  color: #009ceb;
}
.search-signup-card-icon {
  height: 48px;
  width: 48px;
  border-radius: 50%;
  background-color: #ffcea0;
  color: #e3761c;
  font-size: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
}
.search-signup-card-button {
  width: 100%;
  height: 40px !important;
  background-color: #4e6d8b !important;
  font-family: 'Poppins-Medium', 'Poppins Medium', 'Poppins', sans-serif;
  font-weight: 500 !important;
  color: #ffffff !important;
  border: none !important;
  transition: none !important;
  margin: 10px 0 0 0 !important;
}
.search-signup-card-button:hover {
  background-color: #425b75 !important;
}
.search-popular-card {
  width: 100%;
  border: 1px solid #e4e4e4;
  padding: 25px 20px;
}
.search-popular-card li {
  list-style: none;
  font-family: 'Poppins-Regular', 'Poppins', sans-serif;
  color: #666666;
  size: 14px;
  line-height: 32px;
}
.search-popular-card li span {
  font-weight: 500;
}
.search-popular-card-header {
  font-family: 'Poppins-Medium', 'Poppins Medium', 'Poppins', sans-serif;
  font-weight: 500;
  color: #0d2b3a;
  font-size: 16px;
  margin-bottom: 20px;
}
.discover-button-wrapper {
  display: flex;
  width: 100%;
  flex-wrap: wrap;
}
.discover-button {
  height: 35px;
  font-size: 12px;
  padding: 10px;
  margin: 10px 10px 0 0;
  border-radius: 3px;
  border: 1px solid #e4e4e4;
  background-color: #ffffff;
  font-family: 'Poppins-Regular', 'Poppins', sans-serif;
  color: #666666;
  text-align: center;
  cursor: pointer;
}
.discover-button:hover {
  border-color: #333333;
}

/*         SEARCH-PAGE END          */
/*         SITE-CARD Starts          */
.guest-card-wrapper {
  display: flex;
  flex-direction: column;
  width: 100%;
  align-items: center;
  margin-top: -100px;
  font-family: 'Poppins-Regular', 'Poppins', sans-serif;
  z-index: 99;
  background: linear-gradient(
    0deg,
    rgba(255, 255, 255, 1) 82%,
    rgba(255, 255, 255, 0) 100%
  );
}
.guest-header {
  margin-top: 120px;
  background-color: rgba(255, 255, 255, 0);
  box-sizing: border-box;
  color: #0d2b3a;
  text-align: center;
  font-size: 24px;
  max-width: 655px;
  line-height: 1.5;
}
.guest-button {
  margin-top: 30px;
}
.guest-p {
  text-align: center;
  margin-top: 28px;
}
.guest-p p {
  font-size: 14px;
  color: #666;
}
.guest-p p span {
  color: #009ceb;
  cursor: pointer;
  margin-left: 8px;
}
.guest-p p span:hover {
  text-decoration: underline;
}
.search-site-card {
  position: relative !important;
  width: 100%;
  border: 1px solid #e4e4e4;
  margin-bottom: 20px;
  padding: 24px 24px 5px 24px;
}

.search-site-card:hover {
  box-shadow: 0px 0px 20px 0px rgb(192 192 192 / 80%);
}

.search-site-card-header-left {
  font-family: 'Poppins-Medium', 'Poppins Medium', 'Poppins', sans-serif !important;
  font-weight: 500;
  color: #0d2b3a;
  font-size: 16px !important;
}
.search-site-card-header-right {
  font-family: 'Poppins-Regular', 'Poppins', sans-serif;
  color: #666666;
  font-size: 16px;
  display: flex !important;
  justify-content: space-between;
  align-items: center;
}
.search-site-card-header-right span {
  color: #e3761c;
  font-size: 22px;
}

.search-site-card-descrition {
  font-family: 'Poppins-ExtraLight', 'Poppins ExtraLight', 'Poppins', sans-serif;
  font-weight: 300;
  color: #666666;
  font-size: 14px;
  width: 95%;
  margin-top: 10px;

  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 2; /* number of lines to show */
  line-height: 1.5rem; /* fallback */
  max-height: 3rem; /* fallback */
}

.site-card-divider {
  min-width: unset;
  border-color: #cccccc !important;
  margin: 26px 0 20px 0 !important;
}
.search-site-card-body-wrapper,
.search-site-card-header-right span,
.site-card-img-left-right div,
.search-site-card-header-left {
  cursor: pointer;
}
.search-site-card-body-left p {
  font-family: 'Poppins-Regular', 'Poppins', sans-serif;
  color: #0d2b3a;
  line-height: 16px;
  font-size: 12px;
}
.search-site-card-body-left p span {
  padding: 3px 8px !important;
  border-radius: 3px !important;
  border: 1px solid #cccccc !important;
  background-color: #f0f0f0 !important;
  box-sizing: border-box !important;
  font-family: 'Poppins-Regular', 'Poppins', sans-serif !important;
  color: #999999 !important;
  margin: 10px 12px !important;
}
.search-site-card-body-left a {
  font-family: 'Poppins-Regular', 'Poppins', sans-serif !important;
  line-height: 16px;
  font-size: 12px;
  color: #009ceb !important;
}
.search-site-card-body-left a:hover {
  color: rgb(0, 128, 200) !important;
}
.search-site-card-pi-profile {
  display: flex;
  width: 100%;
  align-items: center;
}
.search-site-card-pi-profile-img {
  min-height: 42px;
  min-width: 42px;
  max-height: 42px;
  max-width: 42px;
  height: 42px;
  width: 42px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 50%;
  border: 1px solid #cccccc;
  margin-right: 15px;
  overflow: hidden;
}
.search-site-card-pi-profile-img img {
  height: 100%;
  width: 100%;
}
.search-site-card-pi-profile-text {
  overflow: hidden;
}
.search-site-card-pi-profile-text div {
  font-family: 'Poppins-Regular', 'Poppins', sans-serif;
  color: #666666;
  overflow-wrap: break-word;
  font-size: 14px;
}
.search-site-card-pi-profile-text p {
  font-family: 'Poppins-ExtraLightItalic', 'Poppins ExtraLight Italic',
    'Poppins', sans-serif;
  font-style: italic;
  font-weight: 300;
  color: #666666;
  overflow-wrap: break-word;
  font-size: 14px;
  margin: 8px 0 0 0 !important;
}

.search-site-card-label,
.search-site-card-value {
  font-family: 'Poppins-Regular', 'Poppins', sans-serif;
  line-height: 16px;
  font-size: 12px;
  margin-bottom: 5px;
  overflow-wrap: break-word;
}
.search-site-card-label {
  color: #999999;
}
.search-site-card-value {
  color: #666666;
}
.show-study-button-wrapper {
  position: absolute !important;
  bottom: 0px !important;
  left: 60% !important;
  transform: translateX(-50%) !important;
  width: 190px !important;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 999;
}
.show-study-button {
  margin-bottom: 15px !important;
  cursor: pointer;
  border-radius: 3px;
  border: 1px solid #e4e4e4;
  background-color: #ffffff;
  font-family: 'Poppins-Regular', 'Poppins', sans-serif;
  color: #999999;
  font-size: 12px;
  padding: 7px 20px 7px 25px;
  display: flex;
  justify-content: center;
  align-items: center;
}
.show-study-button:hover {
  border-color: #009ceb;
  color: #009ceb;
}
.show-study-button span {
  margin-left: 15px;
  height: 20px;
}
.search-card-study-table-row-wrapper {
  overflow-y: auto;
  width: 100%;
}
.search-card-study-table-row {
  min-width: 500px;
  width: 100%;
  margin: 5px 0;
}

.search-site-card-left-wrapper {
  display: flex !important;
  justify-content: center;
}

.search-site-card-left {
  width: 90%;
  padding-bottom: 60px;
}
.search-site-card-imgs {
  width: 100% !important;
  height: 100% !important;
  display: flex !important;
  justify-content: center;
  align-items: center;
}

.search-site-card-imgs img {
  max-width: 100%;
  max-height: 100px;
  background-color: #fff;
}

.ant-carousel .slick-dots-bottom {
  bottom: -76px !important;
}

.slick-dots li.slick-active {
  width: 7px !important;
}
.slick-dots li.slick-active button {
  background-color: #f39331 !important;
}

.slick-dots li {
  margin: 0 3px !important;
  width: 7px !important;
  height: 7px !important;
}
.slick-dots li button {
  background-color: #e4e4e4 !important;
  opacity: unset !important;
  height: 100% !important;
  width: 100% !important;
  border-radius: 50% !important;
}
.slick-dots li button:hover {
  opacity: 0 !important;
}
.site-card-img-left-right {
  position: absolute;
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  padding: 0 12%;
  top: 145px;
}
.site-card-img-left-right div {
  color: #e4e4e4;
  font-size: 22px;
  z-index: 999;
}
.site-card-img-left-right div:hover {
  color: #7f7f7f;
}

.collection-modal,
.report-modal {
  min-width: 40%;
}

.collection-modal-header {
  font-family: 'Poppins-Medium', 'Poppins Medium', 'Poppins', sans-serif;
  font-weight: 500;
  color: #0d2b3a;
  font-size: 22px;
}
.collection-modal-body {
  max-height: 340px;
  overflow-y: auto;
  border: 1px solid #e4e4e4;
}
.collection-modal-body-header {
  font-family: 'Poppins-Medium', 'Poppins Medium', 'Poppins', sans-serif;
  font-weight: 500;
  color: #0d2b3a;
  font-size: 18px;
  margin: 16px;
}
.collection-modal-body-list {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 16px 0;
  margin: 0 16px;
  border-bottom: 1px solid #e4e4e4;
}
.collection-modal-body-list-right-button {
  border-radius: 3px;
  background-color: #4e6d8b;
  box-sizing: border-box;
  font-family: 'Poppins-Medium', 'Poppins Medium', 'Poppins', sans-serif;
  font-weight: 500;
  color: #ffffff;
}
.collection-modal-body-list-right-button:hover {
  background-color: #345270 !important;
}
.collection-modal-body-list-right-button-cancel {
  border-radius: 3px;
  background-color: #c04040;
  box-sizing: border-box;
  font-family: 'Poppins-Medium', 'Poppins Medium', 'Poppins', sans-serif;
  font-weight: 500;
  color: #ffffff;
}
.collection-modal-body-list-right-button-cancel:hover {
  background-color: #ff4d4f !important;
}
.collection-modal-body-list-left {
  display: flex;
  align-items: center;
}
.collection-modal-body-list-left-img {
  height: 100px !important;
  width: 100px !important;
  border: 1px solid #e4e4e4;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-right: 15px;
}
.collection-modal-body-list-left-img img {
  max-width: 100% !important;
  max-height: 100% !important;
}
.collection-modal-body-list-left-text a {
  font-family: 'Poppins-Medium', 'Poppins Medium', 'Poppins', sans-serif;
  font-weight: 500;
  color: #0d2b3a;
  font-size: 14px;
  word-break: break-word;
}
.collection-modal-body-list-left-text a:hover {
  color: #009ceb;
}
.collection-modal-body-list-left-text p {
  font-family: 'Poppins-Regular', 'Poppins', sans-serif !important;
  color: #666666;
  font-size: 14px;
}
.collection-modal-footer-button {
  border-radius: 3px !important;
  background-color: #009ceb !important;
  font-family: 'Poppins-Medium', 'Poppins Medium', 'Poppins', sans-serif !important;
  font-weight: 500 !important;
  color: #ffffff;
  font-size: 14px !important;
}
.collection-modal-footer-button:hover {
  background-color: #0471a8 !important;
}
.ant-modal-footer {
  text-align: unset !important;
}
.new-collection-modal-body {
  margin-bottom: 20px;
}
.new-collection-modal-body-label {
  margin: 20px 0 18px 0;
}
.new-collection-modal-footer-save,
.new-collection-modal-footer-cancel {
  border-radius: 3px !important;
  font-family: 'Poppins-Medium', 'Poppins Medium', 'Poppins', sans-serif !important;
  font-weight: 500 !important;
  color: #ffffff !important;
  font-size: 14px !important;
}
.new-collection-modal-footer-save {
  background-color: #4e6d8b !important;
}
.new-collection-modal-footer-cancel {
  background-color: #cccccc !important;
}
.new-collection-modal-footer-save:hover {
  background-color: #3b5c7c !important;
}
.new-collection-modal-footer-cancel:hover {
  background-color: #b7b7b7 !important;
  border-color: #b7b7b7 !important;
}
.remove-collection-modal-header {
  display: flex;
}
.remove-collection-modal-header-icon {
  color: #c04040;
  font-size: 48px !important;
}
.update-collection-modal-header-icon {
  color: #3b5c7c;
  font-size: 48px !important;
}
.remove-collection-modal-header-text {
  margin: 12px;
  font-family: 'Poppins-Medium', 'Poppins Medium', 'Poppins', sans-serif;
  font-weight: 500;
  color: #0d2b3a;
}
.remove-collection-modal-body {
  font-family: 'Poppins-Light', 'Poppins Light', 'Poppins', sans-serif;
  font-weight: 200;
  color: #666666;
  line-height: 22px;
  font-size: 14px;
  margin: 12px;
}
.remove-collection-modal-footer {
  margin-left: 12px;
}
.remove-collection-modal-footer-cancel,
.remove-collection-modal-footer-remove {
  border-radius: 3px !important;
  font-family: 'Poppins-Medium', 'Poppins Medium', 'Poppins', sans-serif !important;
  font-weight: 500 !important;
  font-size: 14px !important;
}
.remove-collection-modal-footer-cancel {
  color: #ffffff !important;
  background-color: #c04040;
}
.remove-collection-modal-footer-remove {
  color: #666666 !important;
}
.update-collection-modal-footer-cancel {
  color: #666666 !important;
}
.update-collection-modal-footer-update {
  color: #fff !important;
  background-color: #3b5c7c;
}
.update-collection-modal-footer-update:hover {
  background-color: #345270 !important;
}
.remove-collection-modal-footer-cancel:hover {
  border-color: #c04040 !important;
  background-color: #aa2626;
}
.remove-collection-modal-footer-remove:hover,
.update-collection-modal-footer-cancel:hover {
  color: #aa2626 !important;
}
/*         SITE-CARD END          */

/*         SITE Page Starts      */
.site-skaleton-img .ant-skeleton-image {
  height: 100% !important;
}
.site-page-container {
  margin: 40px 10%;
}
.site-page-back {
  width: fit-content;
  background-color: rgba(255, 255, 255, 0);
  box-sizing: border-box;
  font-family: 'Poppins-Regular', 'Poppins', sans-serif;
  color: #999999;
  font-size: 14px;
  cursor: pointer;
}
.site-page-back span {
  font-size: 18px;
  margin-right: 10px;
}
.site-page-back:hover {
  color: rgb(0, 156, 235);
}
.site-page-loading-container {
  height: 70vh;
}
.site-page-header-left {
  padding: 12px !important;
}
.site-page-back,
.site-page-header-left,
.site-page-header-left-logo,
.site-page-header-right,
.site-page-header-right-add {
  display: flex;
  align-items: center;
}
.site-page-header {
  display: flex;
  justify-content: space-between;
  margin: 20px 0 30px 0;
}

.site-page-header-left-logo {
  justify-content: center;
  height: 70px;
  width: 150px;
  padding: 8px 0;
  margin-right: 20px;
  border-radius: 5px;
  border: 1px solid #cccccc;
  background-color: #ffffff;
}
.site-page-header-left-logo img {
  max-height: 100%;
  max-width: 100%;
}
.site-page-header-left-text-name {
  font-size: 20px;
  color: #0d2b3a;
  margin-bottom: 12px;
  font-family: 'Poppins-Regular', 'Poppins', sans-serif;
  line-height: 19px;
}
.site-page-header-left-text-org-name {
  font-size: 16px;
  color: #666666;
  font-family: 'Poppins-Regular', 'Poppins', sans-serif;
  line-height: 19px;
}
.sitepage-extra-button-with-text {
  display: flex;
  justify-content: end;
  align-items: flex-start;
}
.sitepage-extra-button-without-text {
  display: none;
}

.site-page-header-right-add {
  font-family: 'Poppins-Regular', 'Poppins', sans-serif;
  color: #e3761c;
  font-size: 16px;
}
.site-page-header-right-add:hover {
  text-decoration: underline;
}
.site-page-header-right-add span {
  font-size: 22px;
}
.site-page-header-right-add span:hover {
  color: #ff9933 !important;
}
.site-page-flag {
  color: #c04040;
  font-size: 20px;
  cursor: pointer;
}
.site-page-flag:hover {
  color: #aa1515;
}
.site-page-body span {
  font-family: 'Poppins-Medium', 'Poppins Medium', 'Poppins', sans-serif;
  font-weight: 500;
  color: #0d2b3a;
  font-size: 22px;
  margin-bottom: 28px;
  display: flex;
}
.site-page-body p {
  padding: 3px 10px 3px 10px !important;
  border-radius: 3px !important;
  border: 1px solid #cccccc !important;
  background-color: #f0f0f0 !important;
  font-family: 'Poppins-Regular', 'Poppins', sans-serif !important;
  color: #999999 !important;
  line-height: 20px !important;
  font-size: 12px !important;
  margin-left: 15px;
}
.studies-table-wrapper {
  overflow-x: auto;
}
.studies-table {
  min-width: 780px;
  width: 100%;
}
.studies-table-header {
  padding: 10px 8px;
  background-color: #f0f0f0;
  margin: 0 !important;
}
.studies-table-header-col {
  font-family: 'Poppins-Medium', 'Poppins Medium', 'Poppins', sans-serif !important;
  font-weight: 500 !important;
  color: #666666 !important;
  text-align: left;
  font-size: 14px;
  display: flex;
  align-items: center;
  justify-content: flex-start;
}
.studies-table-header-col:nth-child(2n),
.studies-table-body-row .study-col:nth-child(2n) {
  justify-content: center !important;
  text-align: center !important;
}
.studies-table-body {
  max-height: 400px !important;
  overflow-x: hidden;
  overflow-y: auto;
  margin: 0 0 40px 0;
}
.studies-table-body-row {
  padding: 10px 8px;
  margin: 0 !important;
}
.studies-table-body-row .study-col {
  font-family: 'Poppins-Regular', 'Poppins', sans-serif;
  color: #666666;
  font-size: 14px;
  line-height: 40px;
  overflow-wrap: break-word;
}
.studies-table-body-row:nth-child(2n) {
  background-color: #fafafa;
}
.hasmore-button {
  display: flex;
  justify-content: center;
  align-items: center;
}
.hasmore-button span {
  font-family: 'Poppins-Regular', 'Poppins', sans-serif !important;
  color: #999999 !important;
  text-align: center !important;
  line-height: unset !important;
  font-size: 12px !important;
  font-weight: 400;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 3px !important;
  border: 1px solid #cccccc !important;
  padding: 5px 15px !important ;
  cursor: pointer;
  margin: 20px 0;
  height: 34px;
}
.hasmore-button span p {
  margin: 0 0 0 15px !important;
  padding: 0 7px !important;
  background-color: #fff !important;
}
.hasmore-button span:hover {
  border-color: #009ceb !important;
}
.search-site-about-img {
  width: 100%;
}
.search-site-card-imgs-big,
.search-site-card-imgs-small {
  padding: 5px;
  width: 98% !important;
  display: flex !important;
  justify-content: center;
  align-items: center;
  background-color: rgb(255, 255, 255);
  border: 1px solid #e4e4e4;
  margin-bottom: 5px;
}
.search-site-card-imgs-big {
  height: 205px !important;
}
.search-site-card-imgs-small {
  height: 100px !important;
}
.body-container .back-to-top .ant-fade-leave {
  display: none !important;
}
.body-container .back-to-top .ant-float-btn-icon {
  color: #fff !important;
}
.body-container .back-to-top .ant-float-btn-body {
  background-color: #66bbad !important;
}

.search-site-card-imgs-small img,
.search-site-card-imgs-big img {
  max-width: 98%;
  max-height: 98%;
}
.site-about-img-left-right-wrapper {
  display: flex;
  justify-content: center;
  align-items: center;
}
.site-about-img-left-right {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  max-width: 250px;
  margin-top: 20px;
}
.site-about-img-left-right div {
  color: #ababab;
  font-size: 22px;
  z-index: 999;
  cursor: pointer;
}
.site-about-img-left-right div h6 {
  color: #606060;
  font-size: 14px;
  cursor: auto;
}
.site-about-img-left-right div:hover {
  color: #606060;
}
.about-site-table-label {
  font-family: 'Poppins-Medium', 'Poppins Medium', 'Poppins', sans-serif !important;
  font-weight: 500 !important;
  color: #666666 !important;
  font-size: 14px !important;
  margin: 10px 0;
}
.about-site-table-value {
  margin: 11px 0;
}
.about-site-table-value,
.about-site-desc {
  font-size: 14px !important;
  font-family: 'Poppins-Light', 'Poppins Light', 'Poppins', sans-serif !important;
  color: #666666;
  word-break: break-word;
}
.about-site-table-icon {
  font-size: 22px !important;
  color: #0d2b3a;
}
.about-site-desc {
  margin: 50px 0;
  line-height: normal;
}
.about-site-table span {
  font-size: 16px !important;
}
.site-page-pi-profile-pic {
  height: 128px;
  width: 128px;
  margin-bottom: 20px;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  overflow: hidden;
  border: 3px solid #eeeeee;
}
.site-page-pi-profile-pic img {
  max-height: 100%;
  max-width: 100%;
}
.about-principal-table-name {
  font-size: 25px !important;
  line-height: normal !important;
}
.about-principal-table-name span {
  font-size: 20px !important;
  font-family: 'Poppins-Regular', 'Poppins', sans-serif !important;
  color: #0d2b3a !important;
  margin: 0% !important;
}
.about-principal-table-name-desc {
  font-size: 16px !important;
  font-family: 'Poppins-Regular', 'Poppins', sans-serif !important;
  color: #666666 !important;
  margin: 12px 0 27px 0 !important;
}
.report-modal .ant-modal-content {
  padding: 0% !important;
}
.report-modal .ant-modal-header {
  padding: 20px 24px 0 24px !important;
}
.report-modal .ant-modal-body {
  padding-bottom: 20px !important;
}
.report-header {
  font-family: 'Poppins-Medium', 'Poppins Medium', 'Poppins', sans-serif !important;
  font-weight: 500 !important;
  color: #666666 !important;
  font-size: 18px !important;
  padding: 10px 24px;
}
.report-options {
  cursor: pointer;
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 60px;
  color: #666666;
  padding: 0 24px 0 24px !important;
}
.report-input {
  padding: 0 24px 0 24px !important;
}
.report-options p {
  font-family: 'Poppins-Regular', 'Poppins', sans-serif !important;
  font-size: 14px;
  margin: 0% !important;
}
.report-options span {
  color: #666666;
  font-size: 22px;
}
.report-options:hover {
  background-color: rgb(242, 242, 242);
}
.report-options:hover span {
  margin-bottom: 5px !important;
  color: #009ceb !important;
}
.report-footer {
  padding: 20px 24px;
  display: flex;
  justify-content: flex-end;
}
.report-footer-back-button {
  font-family: 'Poppins-Medium', 'Poppins Medium', 'Poppins', sans-serif !important;
  font-weight: 500 !important;
  color: #fff !important;
  border-radius: 3px !important;
  background-color: #8f8f8f;
  font-size: 14px !important;
  padding: 9px 20px !important;
  height: 40px !important;
  border-color: #8f8f8f !important;
}
.report-footer-back-button:hover {
  background-color: #666;
  border-color: #8f8f8f !important;
}
.report-footer-submit-button {
  font-family: 'Poppins-Medium', 'Poppins Medium', 'Poppins', sans-serif !important;
  font-weight: 500 !important;
  color: #ffffff !important;
  border-radius: 3px !important;
  background-color: #4e6d8b;
  font-size: 14px !important;
  padding: 10px 20px !important;
  height: 40px !important;
  margin-left: 20px;
}
.report-footer-submit-button:hover {
  border-color: #315b86 !important;
  background-color: #315b86;
}
.loader-wrappper {
  height: 70vh;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}
.crio-input {
  height: 40px;
  font-size: 14px !important;
  font-family: 'Poppins-Light', 'Poppins Light', 'Poppins', sans-serif !important;
  font-weight: 200 !important;
  border: 1px solid #e6eaf2 !important;
  background-color: #fafbfc !important;
  border-radius: 0% !important;
  width: 90%;
}
.crio-input-select {
  font-size: 14px !important;
  font-family: 'Poppins-Light', 'Poppins Light', 'Poppins', sans-serif !important;
  font-weight: 200 !important;
  border: 1px solid #e6eaf2 !important;
  background-color: #fafbfc !important;
  border-radius: 0% !important;
  width: 90%;
}
.report-success {
  padding: 20px 24px 0 24px !important;
  display: flex;
}
.report-success-icon {
  margin-right: 15px;
  font-size: 35px;
  color: #009245 !important;
}
.report-success-text {
  color: #666666 !important;
}
.report-success-text h5 {
  font-family: 'Poppins-Medium', 'Poppins Medium', 'Poppins', sans-serif !important;
  font-weight: 500 !important;
  font-size: 18px;
}
.report-success-text p {
  font-family: 'Poppins-Regular', 'Poppins', sans-serif !important;
  font-size: 14px !important;
}

/*         SITE Page Ends      */

/*       Forgot password starts    */
.expired-div {
  height: 70vh;
  display: flex;
  justify-content: center;
  align-items: center;
  color: red;
  font-size: 20px;
}

/*       Forgot password ends    */

/*       My List          */

.my-list-container {
  min-height: 60vh;
}
.my-list-header {
  font-size: 32px;
}
.list-card-header p {
  font-family: 'Poppins-Medium', 'Poppins Medium', 'Poppins', sans-serif;
  font-weight: 500;
  color: #0d2b3a;
  font-size: 16px;
  line-height: 22px;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  cursor: pointer;
}
.list-card-header p span {
  font-size: 12px !important;
  padding: 3px 10px 3px 10px !important;
  border-radius: 3px !important;
  border: 1px solid #cccccc !important;
  background-color: #e4e4e4 !important;
  font-family: 'Poppins-Regular', 'Poppins', sans-serif !important;
  color: #666666 !important;
  text-align: center !important;
  line-height: 20px !important;
  margin-right: 10px;
}
.sites-carousel-container {
  border-right: 1.8px solid #e4e4e4;
  position: relative;
  overflow: hidden;
}
.list-sites-carousel {
  display: flex;
  scroll-behavior: smooth;
  overflow-x: hidden;
  padding: 15px 10px;
}
.list-card-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  word-break: break-word;
}
.collection-icon {
  font-size: 22px !important;
  margin: 0 15px;
  cursor: pointer;
  color: #e4e4e4;
}
.collection-icon:hover {
  color: #999999 !important;
}
.sites-scroller {
  display: flex;
  justify-content: flex-end;
  align-items: center;
}
.list-card {
  padding: 20px;
  border: 1px solid #e4e4e4;
  margin: 22px 0;
}
.list-site-card-wrapper {
  position: relative;
}
.list-site-card {
  display: flex;
  cursor: pointer;
  justify-content: space-between;
  align-items: center;
  flex-direction: column;
  width: 150px;
  min-width: 150px;
  height: 150px;
  border: 1px solid #e4e4e4;
  padding: 10px;
  margin-right: 20px;
  position: relative;
}
.list-site-card:hover {
  box-shadow: rgba(0, 0, 0, 0.247) 0 0 5px;
}
.list-card:hover {
  box-shadow: rgba(0, 0, 0, 0.247) 0 0 5px;
}
.list-site-card-delete-icon {
  position: absolute;
  right: 30px;
  top: 10px;
  color: #c04040;
  font-size: 18px;
  cursor: pointer;
  z-index: 99;
}
.list-site-card-delete-icon:hover {
  color: rgb(217, 51, 51);
}
.list-site-card-p {
  height: 40px;
  font-family: 'Poppins-Medium', 'Poppins Medium', 'Poppins', sans-serif;
  font-weight: 500;
  color: #0d2b3a;
  font-size: 14px;
  text-align: center;
  margin-top: 7px !important;
}
.list-site-card-img-wrapper {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 80px;
  width: 130px;
  padding: 2.5px 0;
}
.list-site-card-img {
  max-height: 75px;
  max-width: 130px;
}
.list-card-action {
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  padding: 20px 0 10px 0;
}
.list-card-action-1 {
  margin-bottom: 15px;
  border-radius: 3px;
  background-color: #4e6d8b;
  box-sizing: border-box;
  font-family: 'Poppins-Medium', 'Poppins Medium', 'Poppins', sans-serif;
  font-weight: 500;
  color: #ffffff;
  font-size: 14px;
}
.list-card-action-1:hover {
  color: #ffffff !important;
  background-color: #3b546d;
}
.list-card-action-2 {
  margin-bottom: 15px;
  font-family: 'Poppins-Regular', 'Poppins', sans-serif;
  color: #c04040;
  border: none !important;
  font-size: 14px;
  box-shadow: none !important;
}
.list-card-action-2:hover {
  color: #932f2f !important;
}
.ant-tabs .ant-tabs-ink-bar {
  background: #fafafa !important;
}
.ant-tabs-tab {
  border-radius: 0% !important;
  background: unset !important;
  border: unset !important;
  transition: none !important;
  margin-left: 20px !important;
}
.ant-tabs-tab-active {
  background-color: #fafafa !important;
  border: 1px solid #e4e4e4 !important;
  border-radius: 3px !important;
  border-top: 5px solid #e3761c !important;
}
.ant-tabs-tab-btn {
  font-size: 16px;
  font-family: 'Poppins-Medium', 'Poppins Medium', 'Poppins', sans-serif !important;
  color: #999999 !important;
  font-weight: 500 !important;
  padding: 10px 5px 10px 5px !important;
}
.ant-tabs-tab-btn:hover {
  color: rgb(0, 156, 235) !important;
}
.ant-tabs-tab-active .ant-tabs-tab-btn {
  color: #0d2b3a !important;
}

/*       My List          */

/*         crio-Admin Page Starts      */
.timer {
  font-size: 12px;
  text-align: center;
  color: #932f2f;
}
.block-button {
  background-color: #932f2f;
  border-radius: 3px !important;
  color: #fff;
  font-family: 'Poppins-Regular', 'Poppins', sans-serif !important;
}
.unblock-button,
.unblock-button .ant-btn-default:disabled {
  background-color: #4e6d8b !important;
  border-radius: 3px !important;
  color: #fff !important;
  font-family: 'Poppins-Regular', 'Poppins', sans-serif !important;
}
.link-button {
  font-family: 'Poppins-Regular', 'Poppins', sans-serif !important;
  opacity: 0.8;
}
.link-button:hover {
  opacity: 1;
}
.block-button:hover {
  outline: none !important;
  background-color: #c04040;
  color: #fff !important;
  border-color: #fff !important;
}
.unblock-button:hover {
  outline: none !important;
  background-color: #425b75 !important;
  color: #fff !important;
  border-color: #fff !important;
}
.promotion-check {
  margin: 0 !important;
  font-size: 14px;
  font-family: 'Poppins-Regular', 'Poppins', sans-serif !important;
}
.my-list-body-wrapper {
  display: flex;
  width: 100%;
}
.invitation-list-wrapper {
  width: 100%;
  padding-left: 20px;
  border-left: 1.5px dashed #e4e4e4;
}
.name-ant-space {
  width: 100% !important;
  display: flex !important;
  align-items: baseline !important;
}
.name-ant-space .ant-space-item {
  width: 100% !important;
}
.tab-icon-style {
  margin-right: 15px;
}
.site-page-container {
  margin: 40px 10%;
}
.admin-tabs {
  margin: 30px 0;
}
.admin-tabs .ant-tabs-tab {
  margin-left: 0px !important;
  padding: 5px 10px !important;
}
.tabs-header {
  font-family: 'Poppins-Medium', 'Poppins Medium', 'Poppins', sans-serif;
  color: #0d2b3a;
  font-size: 28px;
  margin: 0 0 38px 0;
  display: flex;
  font-weight: 500;
  align-items: center;
}
.tabs-header p {
  padding: 3px 10px 3px 10px;
  border-radius: 3px;
  border: 1px solid #cccccc;
  background-color: #e4e4e4;
  font-family: 'Poppins-Regular', 'Poppins', sans-serif;
  color: #666666;
  font-size: 12px;
  margin: 0 0 0 20px !important;
}
.invite-button {
  height: 40px;
  padding: 10px 20px 10px 20px;
  border-radius: 3px;
  background-color: #4e6d8b;
  font-family: 'Poppins-Medium', 'Poppins Medium', 'Poppins', sans-serif !important;
  font-weight: 500;
  color: #ffffff;
  margin-top: 20px;
  font-size: 14px !important;
}
.invite-button:hover {
  background-color: #0d2b3a !important;
}
.body-container-tab {
  margin: 0 30px 0 0;
  min-height: 70vh;
}

.site-page-back {
  width: fit-content;
  background-color: rgba(255, 255, 255, 0);
  box-sizing: border-box;
  font-family: 'Poppins-Regular', 'Poppins', sans-serif;
  color: #999999;
  font-size: 14px;
  cursor: pointer;
}

.site-page-back span {
  font-size: 18px;
  margin-right: 10px;
}

.site-page-back:hover {
  color: rgb(0, 156, 235);
}

.site-page-loading-container {
  height: 70vh;
}

.site-page-back,
.site-page-header-left,
.site-page-header-left-logo,
.site-page-header-right,
.site-page-header-right-add {
  display: flex;
  align-items: center;
}
.site-page-header-right-add {
  cursor: pointer;
}
.site-page-header {
  display: flex;
  justify-content: space-between;
  margin: 20px 0 30px 0;
}

.site-page-header-left-logo {
  justify-content: center;
  height: 70px;
  width: 150px;
  padding: 8px 0;
  margin-right: 20px;
  border-radius: 5px;
  border: 1px solid #cccccc;
  background-color: #ffffff;
}

.site-page-header-left-logo img {
  max-height: 100%;
  max-width: 100%;
}

.site-page-header-left-text-name {
  font-size: 20px;
  color: #0d2b3a;
  margin-bottom: 12px;
  font-family: 'Poppins-Regular', 'Poppins', sans-serif;
  line-height: 19px;
}

.site-page-header-left-text-org-name {
  font-size: 16px;
  color: #666666;
  font-family: 'Poppins-Regular', 'Poppins', sans-serif;
  line-height: 19px;
}

.site-page-header-right-add {
  font-family: 'Poppins-Regular', 'Poppins', sans-serif;
  color: #e3761c;
  font-size: 16px;
}

.site-page-header-right-add span {
  font-size: 22px;
}

.site-page-flag {
  color: #c04040;
  font-size: 20px;
}

.site-page-body span {
  font-family: 'Poppins-Medium', 'Poppins Medium', 'Poppins', sans-serif;
  font-weight: 500;
  color: #0d2b3a;
  font-size: 22px;
  margin-bottom: 28px;
}

.block-unblock-modal {
  position: relative;
  background-color: #ffffff;
  background-clip: padding-box;
  border: 0;
  border-radius: 8px;
  box-shadow:
    0 6px 16px 0 rgb(0 0 0 / 8%),
    0 3px 6px -4px rgb(0 0 0 / 12%),
    0 9px 28px 8px rgb(0 0 0 / 5%);
  pointer-events: auto;
}

.blocked-reason-info-icon {
  margin-left: 12px;
  color: #ff4d4f;
  font-size: 18px;
  margin-top: 6px;
}

.unblock-btn {
  color: #4fc659;
  border-color: #4fc659;
}

.sites-left {
  overflow-x: auto;
}

.sites-left-body {
  margin-bottom: 20px;
}

.sites-left-header {
  background-color: #4e6d8b;
  font-family: 'Poppins-Medium', 'Poppins Medium', 'Poppins', sans-serif;
  font-weight: 500;
  color: #ffffff;
  font-size: 14px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 5px 1em;
}

.sites-left-header p {
  font-family: 'Poppins-Regular', 'Poppins', sans-serif;
  color: rgba(255, 255, 255, 0.5);
  font-size: 12px;
  cursor: pointer;
  display: block;
  margin-block-start: 1em;
  margin-block-end: 1em;
  margin-inline-start: 0px;
  margin-inline-end: 0px;
}

.sites-checkbox {
  padding: 8px 0 8px 10px;
  width: 100%;
  font-family: 'Poppins-Regular', 'Poppins', sans-serif !important;
  color: #666666 !important;
  font-size: 12px;
  margin: 0% !important;
  display: flex !important;
  align-items: center !important;
}
.sites-checkbox:hover,
.sites-checkbox-checked {
  background-color: #f0f0f0;
}

.horizontal-divider-class {
  margin: 0 !important;
  left: 6% !important;
  width: 88% !important;
  border-block-start: 1px dashed #c9c9c9 !important;
}

.table_site_name {
  display: flex;

  align-items: center;
}

.pop-reg-666 {
  color: #666;
  font-family: Poppins-Regular, Poppins, sans-serif;
  font-size: 14px;
}

.email {
  word-break: break-all;
}

.title-container {
  padding: 0 16px;
}

.table-header-wrapper,
.table-header-column {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 10px 0;
}

.table-header-wrapper-title {
  font-family: 'Poppins-Regular', 'Poppins', sans-serif;
  color: #dae3ec;
  font-weight: 400 !important;
  font-size: 14px;
}

.col-title {
  padding: 5px 16px;
  font-family: 'Poppins-Medium', 'Poppins Medium', 'Poppins', sans-serif;
  font-weight: 500;
  color: #ffffff;
  font-size: 14px;
}

.ant-side-table {
  min-width: 600px;
}

.ant-table-title {
  background-color: #4e6d8b !important;
  border-radius: 0% !important;
  border: none !important;
  padding: 0 !important;
}

.table_site_name img {
  max-width: 60px;
  max-height: 30px;
  margin-right: 10px;
  background-color: white;
}

.ant-table-row:nth-child(2n) {
  background-color: #f2f2f2;
}
.block-user-btn,
.unblock-user-btn {
  border-radius: 3px !important;
  color: #fff !important;
  font-family:
    Poppins-Medium,
    Poppins Medium,
    Poppins,
    sans-serif !important;
  font-size: 14px !important;
  font-weight: 500 !important;
  background-color: #4e6d8b !important;
}

.cancel-user-btn {
  background-color: #bfbfbf !important;
  border-radius: 3px !important;
  color: #fff !important;
  font-family:
    Poppins-Medium,
    Poppins Medium,
    Poppins,
    sans-serif !important;
  font-size: 14px !important;
  font-weight: 500 !important;
}

/*         Crio-Admin Page Ends      */

/*       user PROFILE STARTS         */
.user-profile {
  min-height: 75vh;
  margin: 40px 10%;
}

.user-profile-loader {
  height: 60vh;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}
.user-profile-left {
  font-family: 'Poppins-Regular', 'Poppins', sans-serif !important;
  color: #0d2b3a !important;
  font-size: 32px;
}
.user-profile-left-img {
  height: 128px;
  width: 128px;
  overflow: hidden;
  display: flex;
  justify-content: center;
  align-items: center;
  border: 4px solid #bfbfbf;
  border-radius: 50%;
  margin-top: 33px;
  background-color: #cacaca;
  color: #fff;
}
.user-profile-left-img img {
  height: 100%;
  width: 100%;
}
.user-profile-right {
  margin: 30px 10px;
}
.user-profile-right-text {
  font-family: 'Poppins-Medium', 'Poppins Medium', 'Poppins', sans-serif !important;
  color: #666666 !important;
  font-size: 16px;
  line-height: 40px;
}
/* user profile ends */

/*      Manage Sites Page Starts      */
.principal-list li {
  display: flex;
  align-items: center;
  margin: 5px 0;
  font-family: 'Poppins-Light', 'Poppins Light', 'Poppins', sans-serif;
  font-weight: 300 !important;
}

.principal-list li img {
  width: 20px;
  overflow: hidden;
  height: 20px;
  border-radius: 50%;
  border: 1px solid #57c2f7;
  margin-right: 10px;
}
.extra-pi-icon {
  margin-left: 30%;
}
.site-right-arrow {
  font-size: 20px;
  color: #36ade9;
}
.manage-sites-area-container {
  display: flex !important;
  flex-direction: column !important;
  background: #fff;
  max-height: 300px !important;
  overflow-x: auto;
}

.manage-sites-checkbox-wrapper {
  font-family: 'Poppins-Regular', 'Poppins', sans-serif !important;
  color: #cccccc !important;
  font-size: 11px !important;
  background: #fafafa !important;
  padding: 12px 12px 15px 12px !important;
  margin-bottom: 0px !important;
}

.manage-site-org-input {
  height: 30px;
  font-size: 14px !important;
  font-family: 'Poppins-Light', 'Poppins Light', 'Poppins', sans-serif !important;
  font-weight: 200 !important;
  border: 1px solid #e6eaf2 !important;
  background-color: #fafbfc !important;
  border-radius: 0% !important;
  width: 90%;
}

/* override   */
.crio-input .ant-select-selector,
.crio-input-select .ant-select-selector {
  height: 100% !important;
  background: transparent !important;
  border: 0 !important;
  border-radius: 0% !important;
}
.crio-input .ant-select-selection-item,
.crio-input-select .ant-select-selection-item {
  font-size: 14px !important;
  font-family: 'Poppins-Light', 'Poppins Light', 'Poppins', sans-serif !important;
  font-weight: 300 !important;
}
.crio-input textarea {
  font-size: 14px !important;
  font-family: 'Poppins-Light', 'Poppins Light', 'Poppins', sans-serif !important;
  font-weight: 300 !important;
}
.ant-checkbox-inner {
  background-color: #fff !important;
  border-radius: 0 !important;
  border-color: #cccccc !important;
  height: 19px !important;
  width: 19px !important;
}
.ant-checkbox-inner::after {
  border-color: #19b5ff !important;
  font-size: 14px !important;
  width: 6.714286px !important;
  height: 10.142857px !important;
}
.ant-checkbox {
  top: unset !important;
}

.ant-input-suffix :hover {
  cursor: pointer !important ;
}

/*      Manage Sites Page Ends      */

/*      Manage Templates Starts    */

.site-expand-icon {
  font-size: 20px !important;
  color: #666666 !important;
}
.site-header-bar {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.site-header-name {
  font-family: 'Poppins-Medium', 'Poppins Medium', 'Poppins', sans-serif !important;
  font-weight: 500 !important;
  color: #0d2b3a !important;
  font-size: 18px !important;
  cursor: pointer;
}
.template-body {
  margin: 0 20px;
}
.template-collapse {
  margin-bottom: 15px;
  transition: all 0.2s ease-in-out;
}
.template-collapse:hover {
  background-color: rgba(0, 0, 0, 0.042) !important;
  box-shadow: 2px 2px 4px 1px rgb(0 0 0 / 12%) !important;
  border-color: #fff !important;
}

.value-tags {
  margin-right: 12px;
  font-size: 14px !important;
  font-family: 'Poppins-Light', 'Poppins Light', 'Poppins', sans-serif !important;
  line-height: 30px;
}
.ant-typography-edit-content {
  margin-left: 15px;
  margin-bottom: 0px !important;
}
.ant-typography-edit-content textarea {
  font-size: 14px !important;
  font-family: 'Poppins-Light', 'Poppins Light', 'Poppins', sans-serif !important;
  border: 1px solid #e6eaf2 !important;
  background-color: #fafbfc !important;
  border-radius: 0% !important;
}
/*      Manage Templates Starts    */

/* hamburger style */
/* Position and sizing of burger button */
.bm-burger-button {
  position: absolute;
  width: 22px;
  height: 18px;
  right: 20px;
  top: 15px;
  display: none;
}
.bm-burger-button span span {
  border-radius: 1px;
}

/* Color/shape of burger icon bars */
.bm-burger-bars {
  background: #fff;
}

/* Color/shape of burger icon bars on hover*/
.bm-burger-bars-hover {
  background: #fff;
}

/* Position and sizing of clickable cross button */
.bm-cross-button {
  height: 24px;
  width: 24px;
  right: 20px !important;
  top: 10px !important;
}

/* Color/shape of close button cross */
.bm-cross {
  background: #bdc3c7;
}

/*
Sidebar wrapper styles
Note: Beware of modifying this element as it can break the animations - you should not need to touch it in most cases
*/
.bm-menu-wrap {
  right: 0px;
  z-index: 1100;
  width: 100% !important;
  top: 0px;
  height: 200px !important;
}

/* General sidebar styles */
.bm-menu {
  background: #0b2532;
  /* padding: 2.5em 1.5em 0; */
  font-size: 1.15em;
  width: 70% !important;
}

/* Morph shape necessary with bubble or elastic */
.bm-morph-shape {
  fill: #0b2532;
  width: 32% !important;
  box-shadow:
    0,
    0 3px 6px 16px rgb(0 0 0 / 12%);
}

/* Wrapper for item list */
.bm-item-list {
  color: #b8b7ad;
  padding: 0.8em;
  position: fixed !important;
  left: 0 !important;
  width: 100% !important;
  display: flex !important;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}

/* Individual item */
.bm-item {
  display: inline-block;
}

/* Styling of overlay */
.bm-overlay {
  background: rgba(0, 0, 0, 0.3);
  right: 0px !important;
}

/*       Page not found & Session timeout     */
.w-200 {
  width: 200px !important;
}

/*         Responsive media         */
@media (max-width: 1200px) {
  .select-bar {
    padding: 15px 20px !important;
  }
}

@media (max-width: 991px) {
  .home-signup {
    padding: 0 8%;
  }
  .home-signup h2 {
    font-size: 24px;
  }
  .home-signup p {
    font-size: 16px;
    line-height: 32px;
  }
  .logo img {
    height: 40px;
  }
  .header {
    height: 60px;
    padding: 0 7%;
  }
  .header ul li Button {
    font-size: 15px !important;
  }
  .hero {
    margin-top: -10%;
    height: 70vh;
    background-size: 100%;
  }
  .select-bar {
    padding: 15px 20px !important;
  }
  .hero-text {
    font-size: 1.3em;
    padding-left: 1em;
  }
  .body-content {
    margin-top: -3em;
  }
  .search-card {
    width: 70%;
    font-size: 1em;
  }
  .search-div {
    margin-right: 18px;
  }
  .body-text,
  .promotion-text {
    width: 75%;
    font-size: 18px;
    line-height: 40px;
  }
}
@media (max-width: 768px) {
  .show-study-button-wrapper {
    left: 50% !important;
  }
  .site-card-img-left-right {
    top: 135px !important;
  }
  .ant-carousel .slick-dots-bottom {
    bottom: -66px !important;
  }
  .promotional-cards-header {
    height: 350px;
  }
  .promotional-cards-header h1 {
    max-width: 90%;
  }
  .promotion-row-card {
    background-blend-mode: soft-light;
  }
  .footer-rights {
    flex-direction: column;
    height: 120px;
    line-height: normal !important;
    margin-bottom: 40px;
  }
  .logo img {
    height: 35px;
  }
  .select-bar {
    padding: 10px 15px !important;
  }
  .search-site-card-header-right span {
    position: absolute;
    top: -114px;
    right: 4px;
  }
  .search-site-card-left-wrapper {
    min-height: 125px !important;
  }
  .search-site-card-imgs {
    height: 100px !important;
  }
  .show-study-button:hover {
    border-color: #e4e4e4;
    color: #999999;
  }
  .collection-modal-body-list-left-img {
    height: 80px !important;
    width: 80px !important;
  }
  .header {
    height: 50px;
    padding: 5px 6%;
  }
  .header ul li Button {
    font-size: 12px !important;
  }
  .hero {
    margin-top: -20%;
  }
  .hero-text {
    font-size: 1.1em;
    padding-left: 0;
  }
  .search-card {
    width: 95%;
    font-size: 0.8em;
  }
  .ant-tabs-tab-btn {
    font-size: 14px;
  }
  .sites-carousel-container {
    border: none !important;
  }
  .list-card-action {
    flex-direction: row;
  }
  .list-sites-carousel {
    overflow-x: scroll;
  }
  .search-div {
    margin: 0;
  }
  .body-text {
    width: 80%;
    font-size: 16px;
    line-height: 35px;
    text-align: center;
  }
  .promotion-text {
    width: 90%;
    font-size: 16px;
    line-height: 35px;
    text-align: center;
  }
  .promotion-img img {
    width: 100%;
  }
  .sites-header {
    font-size: 24px;
  }
  .promotion-card-left h2 {
    font-size: 32px;
  }
  .site-page-container {
    margin: 40px 2% !important;
  }
}
@media (max-width: 568px) {
  .search-warning-tooltip .ant-tooltip-content {
    max-width: 400px !important;
  }
  .sitepage-extra-button-without-text {
    display: flex;
    justify-content: end;
    align-items: flex-start;
  }
  .sitepage-extra-button-with-text {
    display: none;
  }
}
@media (max-width: 500px) {
  .search-warning-tooltip .ant-tooltip-content {
    max-width: 300px !important;
  }
  .site-card-img-left-right {
    top: 125px !important;
  }
  .ant-carousel .slick-dots-bottom {
    bottom: -56px !important;
  }
  .search-div {
    margin: 0 10px 0 0;
  }
  .promotional-cards-header {
    height: 350px;
  }
  .promotional-cards-header h1 {
    font-size: 22px !important;
    max-width: 90%;
  }
  .promotion-img {
    margin-bottom: 45px;
  }
  .demo-warning-modal {
    background-size: 120px;
  }
  .user-profile {
    margin: 30px 2% !important;
  }
  .user-profile-right-text {
    font-size: 14px !important;
    line-height: 32px !important;
    word-wrap: break-word !important;
  }
  .logo2 img,
  .footer-branding img {
    height: 30px;
  }
  .search-section {
    box-shadow: none;
    padding: 15px 0 0 0;
  }
  .select-bar div {
    font-size: 12px;
  }
  .big-selected-label {
    display: none;
  }
  .small-selected-label {
    display: unset;
  }
  .collection-modal-body-list-left-img {
    height: 50px !important;
    width: 50px !important;
  }
  .header {
    height: 50px;
    padding: 10px 6%;
  }
  .header ul li Button {
    font-size: 10px !important;
  }
  .crio-li-menu {
    display: none !important;
  }
  .bm-burger-button {
    display: block !important;
  }
  .logo {
    display: none;
  }
  .logo2 {
    display: flex;
  }
  .otp-input {
    height: 50px !important;
    width: 38px !important;
  }
  .login-page-body {
    padding: 20px 0;
  }
  .hero {
    margin-top: -30%;
    padding: 0 30px;
    box-shadow: none;
  }
  .search-card {
    width: 95%;
    font-size: 0.8em;
  }
  .body-text {
    width: 90%;
    font-size: 14px;
    line-height: 28px;
    margin: 50px 0 80px 0 !important;
  }
  .promotion-text {
    width: 100%;
    font-size: 14px;
    line-height: 2rem;
  }
  .promotion-text p,
  .promotion-text p span {
    font-size: 16px !important;
  }
  .promotion-text h2 {
    font-size: 24px !important;
  }
  .sign-in-logo-text {
    font-size: 20px;
  }
  .sign-in-logo-text img {
    height: 45px;
  }
  .login-card-text {
    font-size: 12px;
  }
  .signin-form {
    width: 100%;
    margin: 26px 0px;
  }
  .change-email {
    width: 100%;
  }
  .body-container {
    margin: 0 15px 90px 15px;
  }
  .user-email {
    font-size: 10px;
  }
  .promotion-card-left {
    font-size: 18px;
    line-height: 2rem;
    text-align: center;
  }
  .promotion-row-card {
    width: 100%;
    padding: 40px 30px 20px 30px;
  }
  .promotion-card-right-row {
    flex-direction: column;
    text-align: center;
  }
  .promotion-card-right-row div {
    width: 100%;
  }
  .promotion-card-right-row div p {
    margin-top: 1.2rem;
  }
  .body-content {
    padding-bottom: 0px;
    margin-top: 1.5rem;
  }
  .footer-branding img {
    margin-right: 0px;
    margin-bottom: 20px;
  }
  .footer-branding {
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
  .footer {
    height: 300px;
  }
}
